<template>
   
    <div id="MainBody" class="ArabicLan">

        <div class="container-fluid" prefix="Header">
            <div class="row " :class="{ 'align-items-center': ($windowWidth>1380) , 'align-items-start': $windowWidth<1380}">
                <div class="col-4">
                    <img class="img-fluid" :style="$windowWidth>1380 ? 'max-height:50px' : 'max-height:40px;margin-top: 10px;'" src="@/assets/india/image/logo.png" />
                </div>
                <div class="col-8">
                    <p class="text-right m-0">
                        <a class="btn btn-warning m-2 btn_blue_active" @click="LanguageChange" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">English</a>
                        <a @click="scrollToCourse" class="btn  btn-info m-2 btn_blue_active" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">المقرر + الأسعار</a>
                        <router-link :to="{ name: 'BookDemoAR',params: { username: $route.params.username }}" v-slot="{ href, navigate}" custom>
                            <a class="btn btn-info m-2 btn_blue_active" :href="href" @click="navigate" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">احجز تجربة مجانية الآن</a>
                        </router-link>
                        <router-link :to="{ name: 'PayNowAR',params: { username: $route.params.username }}" v-slot="{ href, navigate}" custom>
                            <a class="btn btn-warning m-2 btn_orange_active" :href="href" @click="navigate" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">شراء الدورة</a>
                        </router-link>

                    </p>
                </div>
            </div>
        </div>
        <div class="row position-relative m-0">
            <div class="container-fluid p-4">
                <img class="position-absolute" style="height:150px;" src="@/assets/india/image/bg-material.png" />
                <img class="position-absolute" style="height:80px;left:37%;top:30%" src="@/assets/india/image/bg-material.png" />
                <img class="position-absolute" style="left:1.5rem" src="@/assets/india/image/bg-design-1.png" />
                <div class="row m-0">
                    <div class="p-md-5 p-sm-3 p-1" :class="{ 'col-7': ($windowWidth>=1000) , 'col-12': $windowWidth<1000}">

                        <h1 v-if="$windowWidth>=1000">
                            دورات تدريبية 1-إلى-1 مباشرة عن الذكاء الاصطناعي، علم الروبوتات وانترنت الأشياء مدمجة مع باقات تعليمية
                        </h1>
                        <h2 v-else>
                            دورات تدريبية 1-إلى-1 مباشرة عن الذكاء الاصطناعي، علم الروبوتات وانترنت الأشياء مدمجة مع باقات تعليمية
                        </h2>
                        <h1 style="color:#FF6634" v-if="$windowWidth>=1000">
                            لجعل التعليم ممتعاً ومشوق!!
                        </h1>
                        <h2 style="color:#FF6634" v-else>
                            لجعل التعليم ممتعاً ومشوق!!
                        </h2>
                        <img v-if="$windowWidth<1000" style="width:100%" src="@/assets/sa/img/1.jpg" />
                        <h1 style="color:#4B4B4D" class="mt-5" v-if="$windowWidth>=1000">
                            <strong>للطلاب من عمر 6-16 سنة</strong>
                        </h1>
                        <h2 style="color:#4B4B4D" class="mt-5" v-else>
                            <strong>للطلاب من عمر 6-16 سنة</strong>
                        </h2>
                        <p :style="$windowWidth>=1000?'font-size:1.5rem':'font-size:1rem'">
                            حيث يحصل الأطفال على أفضل تجارب التعليم!!
                        </p>
                        <router-link :to="{ name: 'BookDemoAR',params: { username: $route.params.username }}" v-slot="{ href, navigate}" custom>
                            <a class="btn  btn-warning btn_orange_active" :href="href" @click="navigate" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">احجز تجربة مجانية الآن</a>
                        </router-link>
                    </div>
                    <div class="col-5 p-5" v-if="$windowWidth>=1000">
                        <img style="width:100%" src="@/assets/sa/img/1.jpg" />
                    </div>
                </div>
            </div>
            <img class="position-absolute p-0" style="width:100%;bottom:0;z-index:-1" src="@/assets/india/image/header-bg.png" />
        </div>
        <div :style="{'backgroundImage': 'url('+require('@/assets/india/image/bg.jpg')+')'}">
            <div class="container-fluid py-5">
                <h3 class="text-center p-lg-4 p-md-3 p-sm-2 p-1" style="color:#404040;font-weight:600">
                    رحلة التحول من مستهلك عادي إلى صانع مستقبلي في مجال التكنولوجيا
                </h3>
                <div class="row align-items-center justify-content-center">
                    <div class="col-2  col-sm-1 text-center">
                        <p class="text-center">
                            <a @click="StudentInit.slideNext()" style="cursor:pointer">
                                <img class="img-fluid" src="@/assets/india/image/slider-arrow-right.png" />
                            </a>
                        </p>
                    </div>
                    <div class="col-8 col-sm-10">
                        <swiper :slidesPerView="'1'" dir="rtl" :spaceBetween="30" :breakpoints="{
  '500': {
    'slidesPerView': 1,
    'spaceBetween': 20
  },
  '600': {
    'slidesPerView': 2,
    'spaceBetween': 40
  },
  '1024': {
    'slidesPerView': 3,
    'spaceBetween': 50
  }
}" class="mySwiper" @swiper="onStudentInit">
                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/Course_sa_web_slider_1.jpg" />
                            </swiper-slide>


                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/Course_sa_web_slider_2.jpg" />
                            </swiper-slide>
                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/course_sa_web_slider_4.jpg" />
                            </swiper-slide>
                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/course_sa_web_slider_5.jpg" />
                            </swiper-slide>
                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/course_sa_web_slider_6.jpg" />
                            </swiper-slide>
                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/course_sa_web_slider_7.jpg" />
                            </swiper-slide>
                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/course_sa_web_slider_9.jpg" />
                            </swiper-slide>
                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/course_sa_web_slider_11.jpg" />
                            </swiper-slide>
                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/course_sa_web_slider_12.jpg" />
                            </swiper-slide>
                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/course_sa_web_slider_13.jpg" />
                            </swiper-slide>
                            <swiper-slide>
                                <img class="img-fluid" src="@/assets/sa/img/course_sa_web_slider_14.jpg" />
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="col-2 col-sm-1 text-center">
                        <p class="text-center">
                            <a @click="StudentInit.slidePrev()" style="cursor:pointer">
                                <img class="img-fluid" src="@/assets/india/image/slider-arrow-left.png" />
                            </a>
                        </p>
                    </div>
                </div>




            </div>
        </div>
        <div class="row position-relative m-0">
            <div class="container p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;">
                <h1 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>لماذا ينصح بالتعليم المبكر؟</strong></h1>
                <div class="row justify-content-center mt-5">
                    <div class="col-12 col-sm-9 col-md-4 mb-3 mb-sm-2 mb-1 p-3">
                        <p class="text-center mb-0"> <img class="img-fluid rounded" style="border-radius: 4rem !important;" src="@/assets/sa/img/why_early.jpg" /></p>
                    </div>
                    <div class="col-12 col-sm-9  col-md-5 mb-3 mb-sm-2 mb-1">
                        <p class="text-center mb-0"> <img class="img-fluid" src="@/assets/sa/img/why-early-urdu.jpg" /></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row position-relative m-0" style="background:#33CC99" id="MyCourseDiv">
            <div class="container-fluid p-1 p-sm-2 p-md-3 p-lg-4">
                <h1 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>دوراتنا</strong></h1>

                <div class="row ">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 p-3">

                        <p class="text-center m-0" style="font-style: italic;">اختيار مجموعة الأعمار</p>
                        <p class="text-center">
                            <a class="btn m-2" @click="setGrade(1)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800, 'btn_orange_active' : (grade==1) , 'btn_orange':grade!=1 }">مجموعة الأعمار +6 </a>
                            <a class="btn m-2" @click="setGrade(2)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800, 'btn_orange_active' : (grade==2) , 'btn_orange':grade!=2 }">مجموعة الأعمار +8</a>
                            <a class="btn m-2" @click="setGrade(3)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800, 'btn_orange_active' : (grade==3) , 'btn_orange':grade!=3 }">مجموعة الأعمار +12</a>
                            <a class="btn m-2" @click="setGrade(4)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800, 'btn_orange_active' : (grade==4) , 'btn_orange':grade!=4 }">مجموعة الأعمار +14</a>
                        </p>

                        <div class="row align-items-center">
                            <div class="col-2  d-sm-block d-md-none">
                                <p class="text-center">
                                    <a @click="CourseInit.slidePrev()" style="cursor:pointer">
                                        <img class="img-fluid" src="@/assets/india/image/slider-arrow-right.png" />
                                    </a>
                                </p>
                            </div>
                            <div class="col-8 col-md-12">
                                <swiper :slidesPerView="'1'" dir="rtl" :spaceBetween="30" @swiper="onCourseInit" :breakpoints="{
  '500': {
    'slidesPerView': 1,
    'spaceBetween': 20
  },
  '600': {
    'slidesPerView': 2,
    'spaceBetween': 40
  },
  '1024': {
    'slidesPerView': 2,
    'spaceBetween': 50
  }
}" class="mySwiper">
                                    <swiper-slide v-if="grade == 1" style="max-height:695px;display: flex;
    justify-content: space-around">
                                        <div style="max-width:350px">
                                            <img class="img-fluid" src="@/assets/sa/img/6_L.png">
                                            <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                                <p style="float: left;width:50%" class="m-0 text-center">
                                                    السعر
                                                </p>
                                                <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center">{{getLernerPrice}}</p>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide v-if="grade == 1" style="max-height:695px;display: flex;
    justify-content: space-around">
                                        <div style="max-width:350px">
                                            <img class="img-fluid" src="@/assets/sa/img/6_A.png">
                                            <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                                <p style="float: left;width:50%" class="m-0 text-center">
                                                    السعر
                                                </p>
                                                <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center">{{getAcheverPrice}}</p>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide v-if="grade == 2" style="max-height:695px;display: flex;
    justify-content: space-around">
                                        <div style="max-width:350px">
                                            <img class="img-fluid" src="@/assets/sa/img/8_L.png">
                                            <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                                <p style="float: left;width:50%" class="m-0 text-center">
                                                    السعر
                                                </p>
                                                <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center">{{getLernerPrice}}</p>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide v-if="grade == 2" style="max-height:695px;display: flex;
    justify-content: space-around">
                                        <div style="max-width:350px">
                                            <img class="img-fluid" src="@/assets/sa/img/8_A.png">
                                            <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                                <p style="float: left;width:50%" class="m-0 text-center">
                                                    السعر
                                                </p>
                                                <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center">{{getAcheverPrice}}</p>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide v-if="grade == 3" style="max-height:695px;display: flex;
    justify-content: space-around">
                                        <div style="max-width:350px">
                                            <img class="img-fluid" src="@/assets/sa/img/12_L.png">
                                            <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                                <p style="float: left;width:50%" class="m-0 text-center">
                                                    السعر
                                                </p>
                                                <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center">{{getLernerPrice}}</p>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide v-if="grade == 3" style="max-height:695px;display: flex;
    justify-content: space-around">
                                        <div style="max-width:350px">
                                            <img class="img-fluid" src="@/assets/sa/img/12_A.png">
                                            <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                                <p style="float: left;width:50%" class="m-0 text-center">
                                                    السعر
                                                </p>
                                                <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center">{{getAcheverPrice}}</p>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide v-if="grade == 4" style="max-height:695px;display: flex;
    justify-content: space-around">
                                        <div style="max-width:350px">
                                            <img class="img-fluid" src="@/assets/sa/img/14_L.png">
                                            <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                                <p style="float: left;width:50%" class="m-0 text-center">
                                                    السعر
                                                </p>
                                                <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center">{{getLernerPrice}}</p>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide v-if="grade == 4" style="max-height:695px;display: flex;
    justify-content: space-around">
                                        <div style="max-width:350px">
                                            <img class="img-fluid" src="@/assets/sa/img/14_A.png">
                                            <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                                <p style="float: left;width:50%" class="m-0 text-center">
                                                    السعر
                                                </p>
                                                <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center">{{getAcheverPrice}}</p>
                                            </div>
                                        </div>
                                    </swiper-slide>


                                </swiper>
                            </div>
                            <div class="col-2 d-sm-block d-md-none">
                                <p class="text-center">
                                    <a @click="CourseInit.slideNext()" style="cursor:pointer">
                                        <img class="img-fluid" src="@/assets/india/image/slider-arrow-left.png" />
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 p-3">
                        <div class="row" style="height: 100%;">
                            <div class="col-md-12 col-sm-6 align-self-center" style="text-align: center;">
                                <p class="text-center"><strong>المنصات المستخدمة</strong></p>
                                <div style="    display: flex;
    justify-content: space-evenly;flex-wrap: wrap;font-weight: 600;
    font-size: 0.8rem;">
                                    <p class="text-center p-2" style="width:50%">
                                        <img style="max-height:50px" src="@/assets/sa/img/t_troot.png" />
                                        <br /><br />
                                        TROOT
                                    </p>
                                    <p class="text-center p-2" style="width:50%">
                                        <img style="max-height:50px" src="@/assets/sa/img/t_python.png" />
                                        <br /><br />
                                        PYTHON
                                    </p>
                                    <p class="text-center p-2" style="width:50%">
                                        <img style="max-height:50px" src="@/assets/sa/img/t_ml.png" />
                                        <br /><br />
                                        ML
                                    </p>
                                    <p class="text-center p-2" style="width:50%">
                                        <img style="max-height:50px" src="@/assets/sa/img/t_arduino.png" />
                                        <br /><br />
                                        ARDUINO
                                    </p>
                                    <p class="text-center p-2" style="width:50%">
                                        <img style="max-height:50px" src="@/assets/sa/img/t_sktetch.png" />
                                        <br /><br />
                                        SCRATCH
                                    </p>
                                    <p class="text-center p-2" style="width:50%">
                                        <img style="max-height:50px" src="@/assets/sa/img/t_tinkercad.png" />
                                        <br /><br />
                                        TINKERCAD
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-6 align-self-end" style="text-align: center;">
                                <router-link :to="{ name: 'BookDemoAR',params: { username: $route.params.username }}" v-slot="{ href, navigate}" custom>
                                    <a class="btn btn_orange_active  m-2" :href="href" @click="navigate" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">حجز تجربة مجانية</a>
                                </router-link>
                                <a @click="downloadPdf" class="btn btn_orange   m-2" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">تحميل مقرر الدورة</a>
                            </div>
                        </div>
                    </div>
                </div>

                <p class="text-center">مبلغ الدورة قابل للاسترداد دون أي سؤال</p>
            </div>
        </div>
        <div class="row position-relative m-0">
            <div class="container-fluid p-1 p-sm-2 p-md-3 p-lg-4" style="padding-top:0px!important;padding-bottom:0px !important">
                <img class="position-absolute" style="height:150px;top:100px" src="@/assets/india/image/bg-design-2.png" />
                <img class="position-absolute" style="left:1.5rem" src="@/assets/india/image/bg-4.png" />
                <h1 class="text-center p-1 p-sm-2 p-md-3 p-lg-4"><strong>معنا، التعليم أقوى وأفضل</strong> </h1>
                <div class="row m-0 justify-content-between">
                    <div class="col-md-5 col-lg-3 col-sm-4 col-4">
                        <p class="m-0"><img src="@/assets/sa/img/with_us_1.jpg" style="max-height:400px" class="img-fluid" /></p>
                    </div>
                    <div class="col-md-7 col-sm-8 col-8" v-if="$windowWidth<990">
                        <p class="m-0"><img src="@/assets/sa/img/with_us_2.jpg" style="max-height:400px" class="img-fluid" /></p>
                    </div>
                    <div class="col-md-12 col-lg-4 col-sm-12 ">
                        <p class="text-center p-2 m-3" style="background:#FFCC99;border-radius: 20px 20px 0px 0px">
                            ليست مجرد تعليم مباشر، إنما هي أيضاً نماذج مراجعيه وتقييميه
                        </p>
                        <p class="text-center p-2 m-3" style="background:#33CC99">
                            ليست محصورة بالبرمجة والتطبيقات، بل أيضاً بناء الروبوتات، الأدوات الذكية والمزيد

                        </p>
                        <p class="text-center p-2 m-3" style="background:#FFCC00">
                            ليست فقط على الانترنت، إذ تضيف خبرة عملية للطلاب عن طريق حافظة معدات تعليمية
                        </p>
                        <p class="text-center p-2 m-3" style="background:#FF9999;border-radius:0px 0px 20px 20px ">
                            ليست وقت تعليم فحسب، بل وقت اللعب والمتعة
                        </p>
                    </div>
                    <div class="col-md-4" v-if="$windowWidth>990">
                        <p class="m-0"><img src="@/assets/sa/img/with_us_2.jpg" style="max-height:400px" class="img-fluid" /></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid pt-5 pr-5">
            <div class="row justify-content-center position-relative">
                <img class="img-fluid position-absolute" style="right:0px;" src="@/assets/india/image/bg-5.png" />
                <div class="col-12">
                    <h1 class="text-center">
                        <strong>"نضمن حصول الآباء على الأفضل لأطفالهم"</strong>
                    </h1>

                </div>
                <div class="col-sm-6 col-md-4 col-6 p-2">
                    <p class="text-center"><img class="img-fluid" style="max-width:300px;width:100%" src="@/assets/sa/img/HOBY_1.jpg" /></p>
                    <p class="text-center">دورات أونلاين 1:1 مباشرة</p>
                </div>
                <div class="col-sm-6 col-md-4 col-6  p-2">
                    <p class="text-center"><img class="img-fluid" style="max-width:300px;width:100%" src="@/assets/sa/img/HOBY_2.jpg" /></p>
                    <p class="text-center">أفضل المنصات لتعليم البرمجة</p>
                </div>
                <div class="col-sm-6 col-md-4 col-6  p-2">
                    <p class="text-center"> <img class="img-fluid" style="max-width:300px;width:100%" src="@/assets/sa/img/HOBY_3.jpg" /></p>
                    <p class="text-center">مناهج مبتكرة من قبل مهندسين أفضل الجامعات في العالم</p>
                </div>
                <div class="col-sm-6 col-md-4 col-6  p-2">
                    <p class="text-center"><img class="img-fluid" style="max-width:300px;width:100%" src="@/assets/sa/img/HOBY_4.jpg" /></p>
                    <p class="text-center">التعلم خلال اللعب</p>
                </div>
                <div class="col-sm-6 col-md-4 col-6  p-2">
                    <p class="text-center"><img class="img-fluid" style="max-width:300px;width:100%" src="@/assets/sa/img/HOBY_5.jpg" /></p>
                    <p class="text-center">جدولة سهلة للدروس</p>
                </div>
                <div class="col-sm-6 col-md-4 col-6  p-2">
                    <p class="text-center"><img class="img-fluid" style="max-width:300px;width:100%" src="@/assets/sa/img/HOBY_6.jpg" /></p>
                    <p class="text-center">دورات متنوعة على حسب عمر الطفل</p>
                </div>
                <div class="col-12">
                    <p class="text-center">
                        نضمن من خلال دوراتنا استيعاب وفهم الأطفال للأساسيات عن طريق أساليب تعليم ترفيهية وممتعة
                    </p>
                </div>
            </div>


        </div>
        <div class="row position-relative m-0" style="background:#69D4C4">
            <div class="container p-1 p-sm-2 p-md-3 p-lg-4" style="padding-top:0px!important;padding-bottom:0px !important">
                <h1 class="text-center p-1 p-sm-2 p-md-3 p-lg-4"><strong>شاهد من قلب الحدث</strong> </h1>
                <h4 class="text-center p-2" style="font-weight:600">
                    تخلص من الضغوطات وقلل الوقت الذي يضيعه طفلك على الشاشة
                </h4>
                <div class="row m-0 justify-content-center align-items-center">
                    <div class="col-md-3 col-12" v-if="$windowWidth>800">
                        <p class="m-0"><img src="@/assets/sa/img/watch_action.png" style="max-height:400px" class="img-fluid" /></p>
                    </div>
                    <div class="col-2 col-sm-1 text-center">
                        <p class="text-center">
                            <a @click="YoutubeInit.slideNext()" style="cursor:pointer">
                                <img class="img-fluid" src="@/assets/india/image/slider-arrow-right.png" />
                            </a>
                        </p>
                    </div>
                    <div class="col-md-6 col-8">
                        <swiper :slidesPerView="'1'" dir="rtl" :spaceBetween="30" @swiper="onYoutubeInit">
                            <swiper-slide>
                                <p class="text-center">
                                    <iframe width="620" style="max-width:100%" height="380"
                                            src="https://www.youtube.com/embed/BBkzgtcD6Qo">
                                    </iframe>
                                </p>
                                <h5 class="text-center p-md-2 p-0" style="font-weight:600">
                                    اكتشف أحدث أساليب البرمجة العملية مع تينكرلي

                                </h5>
                            </swiper-slide>
                            <swiper-slide>
                                <p class="text-center">
                                    <iframe width="620" style="max-width:100%" height="380"
                                            src="https://www.youtube.com/embed/0z-Nooo5o8c">
                                    </iframe>
                                </p>
                                <h5 class="text-center p-md-2 p-0" style="font-weight:600">
                                    قلل الوقت غير المنتج على الشاشة لطفلك مع تينكرلي
                                </h5>
                            </swiper-slide>
                        </swiper>

                    </div>
                    <div class="col-2 col-sm-1 text-center">
                        <p class="text-center">
                            <a @click="YoutubeInit.slidePrev()" style="cursor:pointer">
                                <img class="img-fluid" src="@/assets/india/image/slider-arrow-left.png" />
                            </a>
                        </p>
                    </div>
                    <div class="col-md-3 col-12" v-if="$windowWidth<800">
                        <p class="m-0"><img src="@/assets/sa/img/watch_action.png" style="max-height:400px" class="img-fluid" /></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid py-5" style="background:#FFCC00">
            <h1 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>مدرسون معتمدون</strong></h1>
            <h3 class="text-center">نضمن أن التعليم لعب، واللعب إنتاجية</h3>

            <div class="row align-items-center">
                <div class="col-2 col-sm-1 text-center">
                    <p class="text-center">
                        <a @click="TeacherInit.slideNext()" style="cursor:pointer">
                            <img class="img-fluid" src="@/assets/india/image/slider-arrow-right.png" />
                        </a>
                    </p>
                </div>
                <div class="col-8 col-sm-10">
                    <swiper :slidesPerView="'1'" dir="rtl" :spaceBetween="30" :breakpoints="{
  '500': {
    'slidesPerView': 1,
    'spaceBetween': 20
  },
  '600': {
    'slidesPerView': 2,
    'spaceBetween': 40
  },
  '1024': {
    'slidesPerView': 3,
    'spaceBetween': 50
  }
}" class="mySwiper" @swiper="onTeacherInit">
                        <swiper-slide>
                            <div style="background:#fff;min-height: 500px;" class="px-0 px-sm-1 px-md-2 px-lg-3 py-3">
                                <h5 class="text-center">برنامج السنوات الابتدائية</h5>
                                <h5 class="text-center" style="color:#A6A6A6">أعمار 6+ و 8+</h5>
                                <p class="text-center"><img class="img-fluid" style="width: 60%;max-width:190px" src="@/assets/india/image/pranjal.png" /></p>
                                <h4 class="text-center" style="color:#FF6634">Pranjal Sharma</h4>
                                <p style="color:#A6A6A6" class="text-center">
                                    حاصل على ماجستير في الميكاترونيكس مع خبرة أكثر من 4 سنوات في التعليم والدورات التدريبية عبر الانترنت
                                </p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div style="background:#fff;min-height: 500px;" class="px-0 px-sm-1 px-md-2 px-lg-3 py-3">
                                <h5 class="text-center">برنامج السنوات المتوسطة</h5>
                                <h5 class="text-center" style="color:#A6A6A6">عمر 12+</h5>
                                <p class="text-center"><img class="img-fluid" style="width: 60%;max-width:190px" src="@/assets/india/image/deven.png" /></p>
                                <h4 class="text-center" style="color:#FF6634">Devendra Rathore</h4>
                                <p style="color:#A6A6A6" class="text-center">
                                    مهندس توجه للتعليم مع خبرة أكثر من 3 سنوات قدم فيها أكثر من 50 ورشة عمل ودرب أكثر من 3000 طالب ومدرس.
                                </p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div style="background:#fff;min-height: 500px;" class="px-0 px-sm-1 px-md-2 px-lg-3 py-3">
                                <h5 class="text-center">برنامج السنوات العليا</h5>
                                <p class="text-center" style="color:#A6A6A6">عمر 14+</p>
                                <p class="text-center"><img class="img-fluid" style="width: 60%;max-width:190px" src="@/assets/india/image/kapil.png" /></p>
                                <h4 class="text-center" style="color:#FF6634">Kapil Arya</h4>
                                <p style="color:#A6A6A6" class="text-center">
                                    خريج جامعة آي آي تي دلهي، خبرة أكثر من 6 سنوات في مجال البحث التربوي والمناهج والتدريب. أشرف على طلاب من أكثر من 100 مدرسة للإبداع.
                                </p>
                            </div>
                        </swiper-slide>
                    </swiper>

                </div>
                <div class="col-2 col-sm-1 text-center">
                    <p class="text-center">
                        <a @click="TeacherInit.slidePrev()" style="cursor:pointer">
                            <img class="img-fluid" src="@/assets/india/image/slider-arrow-left.png" />
                        </a>
                    </p>
                </div>
            </div>
        </div>
        <div class="container-fluid position-relative m-0 p-0" style="overflow: hidden;">
            <img v-if="$windowWidth>800" src="@/assets/india/image/bg-10.png" class="position-absolute " style="bottom:0px;right:150px;max-height:450px;" />
            <div class="container p-1 p-sm-2 p-md-3 p-lg-4" style="padding-top:0px!important;padding-bottom:0px !important">
                <h1 class="text-center p-1 p-sm-2 p-md-3 p-lg-4">
                    <strong>تعرف على قدرات العقول الشابة، تينكرلي ستطلق العنان لها!</strong>
                </h1>

            </div>
            <div class="row m-0 align-items-center justify-content-end">

                <div class="col-sm-2 col-1 p-1 col-md-1 col-lg-1">
                    <p class="text-center">
                        <a @click="Mysyper.slidePrev()" style="cursor:pointer">
                            <img class="img-fluid" src="@/assets/india/image/arrow-left-gray.png" />
                        </a>
                    </p>
                </div>
                <div class="col-lg-6 col-sm-7 col-10 col-md-6 ">
                    <swiper @swiper="onSwiper" dir="rtl" :slidesPerView="'auto'" :spaceBetween="10" class="mySwiper">
                        <swiper-slide style="max-width:250px">
                            <div class="mobile m-2 p-3">
                                <p style="margin:0px !important">
                                    <span class="s_red"></span>
                                    <span class="s_green"></span>
                                    <span class="s_yello"></span>
                                </p>
                                <p class="text-center"><img class="img-fluid" src="@/assets/sa/img/little_minds_1_bill_valthi.jpg" style="max-height: 350px;"></p>
                                <h4 class="text-center w-100 mt-2">Lewis</h4>
                                <p class="text-center w-100 m-0" style="color:#A8A8A7;">Robo car</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide style="max-width:250px">
                            <div class="mobile m-2 p-3">
                                <p style="margin:0px !important">
                                    <span class="s_red"></span>
                                    <span class="s_green"></span>
                                    <span class="s_yello"></span>
                                </p>
                                <p class="text-center"><img class="img-fluid" src="@/assets/india/image/what-little-2.jpg" style="max-height: 350px;"></p>
                                <h4 class="text-center w-100 mt-2">Naseera</h4>
                                <p class="text-center w-100 m-0" style="color:#A8A8A7;">Smart Hand Wash</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide style="max-width:250px">
                            <div class="mobile m-2 p-3">
                                <p style="margin:0px !important">
                                    <span class="s_red"></span>
                                    <span class="s_green"></span>
                                    <span class="s_yello"></span>
                                </p>
                                <p class="text-center"><img class="img-fluid" src="@/assets/sa/img/little_minds_3_martin.jpg" style="max-height: 350px;"></p>
                                <h4 class="text-center w-100 mt-2">Dylan </h4>
                                <p class="text-center w-100 m-0" style="color:#A8A8A7;">Robotic Arm</p>
                            </div>
                        </swiper-slide>
                    </swiper>

                </div>
                <div class="col-sm-2 col-1 p-1 col-md-1 col-lg-1">
                    <p class="text-center">
                        <a @click="Mysyper.slideNext()" style="cursor:pointer">
                            <img class="img-fluid" src="@/assets/india/image/arrow-right-gray.png" />
                        </a>
                    </p>
                </div>
            </div>
            <div style="height:300px" v-if="$windowWidth<800"></div>
            <img src="@/assets/india/image/see-what.png" class="position-absolute" style="bottom:120px;right:0px;width:40%;z-index: 1;
    max-width: 300px;min-width:230px;transform:rotateY(180deg);" />
            <div style="background:#FF9933;padding: 50px 100px;padding-right: 0px !important;">
                <h3 style="margin-left: 20%;margin-top: 30px">يستطيع طفلك أيضاً أن يصنعه...</h3>
            </div>
        </div>

        <div class="container-fluid py-5" style="background:#DCF7FE">
            <h1 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>ماذا يقول الآباء؟</strong></h1>
            <div class="container">
                <div class="row ">
                    <div class="col-12 pr-5">
                        <p class="text-right pr-5">
                            <img style="cursor:pointer;height:40px" @click="ParentInit.slideNext()" class="m-2" src="@/assets/india/image/aero-selected-right.png" />
                            <img style="cursor:pointer;height:40px" @click="ParentInit.slidePrev()" class="m-2" src="@/assets/india/image/aero-selected-left.png" />
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <swiper @swiper="OnParentInit" :slidesPerView="'1'" dir="rtl" :spaceBetween="30" :breakpoints="{

  '950': {
    'slidesPerView': 1,
    'spaceBetween': 40
  },
  '1024': {
    'slidesPerView': 2,
    'spaceBetween': 50
  } }">
                            <swiper-slide>
                                <div style="background:#fff;min-height:300px;border-left:10px solid #FFF;border-top-right-radius:25px" class="p-3">
                                    <table>
                                        <tr>
                                            <td style="width:100px">
                                                <img class="img-fluid rounded-circle" style="height:80px;" src="@/assets/sa/img/P_MISHRA.jpg" />
                                            </td>
                                            <td>
                                                <h4 class="text-left mb-0" style="color:#FF6634">A. Mishra </h4>
                                                <h5 class="text-left" style="color:#A6A6A6">Parent, Software Developer, Blue Yonder </h5>
                                            </td>
                                        </tr>
                                    </table>
                                    <p class="text-left pl-5 pt-3">
                                        كان تينكرلي تجربة رائعة ومميزة لطفلي. تعلم طفلي البرمجة بطريقة مدهشة إذ يستطيع بناء روبوتات مثيرة للدهشة بنفسه في عمر صغير. أتقدم بالشكر للأساتذة على جهودهم وعملهم الرائع!
                                    </p>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div style="background:#fff;min-height:300px;border-top-right-radius:25px" class="p-3">
                                    <table>
                                        <tr>
                                            <td style="width:100px">
                                                <img class="img-fluid rounded-circle" style="height:80px;" src="@/assets/sa/img/P_KARALYN_TOR.jpg" />
                                            </td>
                                            <td>
                                                <h4 class="text-left mb-0" style="color:#FF6634">Mrs. Karalyn</h4>
                                                <h5 class="text-left" style="color:#A6A6A6">Parent, Analyst, EY</h5>
                                            </td>
                                        </tr>

                                    </table>
                                    <p class="text-left pl-5 pt-3">
                                        لم أرى ابني يتحدث بهذا الاهتمام عن البرمجة من قبل. كانت الدورة رائعة والأساتذة ذو خبرة وأسلوب لبق. لا عجب أن ابني كان سعيد جداً خلال الدورة.
                                    </p>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div style="background:#fff;min-height:300px;border-top-right-radius:25px" class="p-3">
                                    <table>
                                        <tr>
                                            <td style="width:100px">
                                                <img class="img-fluid rounded-circle" style="height:80px;" src="@/assets/sa/img/P_RIZWAN.jpg" />
                                            </td>
                                            <td>
                                                <h4 class="text-left mb-0" style="color:#FF6634">Mr. Rizwan</h4>
                                                <h5 class="text-left" style="color:#A6A6A6">Parent, Manager, Amazon</h5>
                                            </td>
                                        </tr>

                                    </table>
                                    <p class="text-left pl-5 pt-3">
                                        كانت الرحلة التي خاضها طفلي مع تينكرلي استثنائية بمعنى الكلمة. مجمل الدورة، المعلمون، السلاسة في الاجراءات، التوجيه الرائع، والدعم الا متناهي من الفريق. تمكن طفلي من اكتساب معرفة ممتازة في البرمجة.
                                    </p>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div style="background:#fff;min-height:300px;border-top-right-radius:25px" class="p-3">
                                    <table>
                                        <tr>
                                            <td style="width:100px">
                                                <img class="img-fluid rounded-circle" style="height:80px;" src="@/assets/sa/img/P_VINEETA.jpg" />
                                            </td>
                                            <td>
                                                <h4 class="text-left mb-0" style="color:#FF6634">Mrs. Vineeta</h4>
                                                <h5 class="text-left" style="color:#A6A6A6">Parent, Senior Manager, IBM</h5>
                                            </td>
                                        </tr>

                                    </table>
                                    <p class="text-left pl-5 pt-3">
                                        سعدت جداً عندما رأيت ابنتي غير ملتصقة على الشاشة طوال اليوم، إذ بدأت تصنع الشاشة الخاصة بها عن طريق حافظة الأدوات من تينكرلي. قدمت تينكرلي دمج رائع بين التعليم المرئي والعملي
                                    </p>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div style="background:#fff;min-height:300px;border-top-right-radius:25px" class="p-3">
                                    <table>
                                        <tr>
                                            <td style="width:100px">
                                                <img class="img-fluid rounded-circle" style="height:80px;" src="@/assets/sa/img/P_SOMYA_P.jpg" />
                                            </td>
                                            <td>
                                                <h4 class="text-left mb-0" style="color:#FF6634">Mrs. Somya P.</h4>
                                                <h5 class="text-left" style="color:#A6A6A6">Parent, Software Developer, SAP Labs </h5>
                                            </td>
                                        </tr>

                                    </table>
                                    <p class="text-left pl-5 pt-3">
                                        في البداية، كنت غير مقتنعة بدورات البرمجة على الانترنت لابنتي الصغيرة ولكن أثبتت تينكرلي عكس ذلك. ساعدت تينكرلي ابنتي في فهم أساسيات البرمجة، كما عززت من مهارات التفكير المنطقي وحل المشكلات. أستطيع القول إن دورات تينكرلي تستحق سعرها.
                                    </p>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>

        </div>
        <div class="container-fluid p-lg-5 p-md-3 p-1 py-3" style="background:#BBF0FE">
            <h4 style="font-weight:700" class="text-center">
                حظيت بالتقدير من
            </h4>
            <div class="row justify-content-center mt-5">
                <div class="col-lg-2  col-3">
                    <img class="img-fluid" src="@/assets/india/image/trust-logo-fob.png" />
                </div>
                <div class="col-lg-2  col-3">
                    <img class="img-fluid" src="@/assets/india/image/trust-logo-iee.png" />
                </div>
                <div class="col-lg-2  col-3">
                    <img class="img-fluid" src="@/assets/india/image/trust-logo-nascom.png" />
                </div>
                <div class="col-lg-2  col-3">
                    <img class="img-fluid" src="@/assets/india/image/trust-logo-stem.png" />
                </div>
            </div>
        </div>
        <div class="container-fluid p-lg-5 p-md-3 p-1">

            <div class="row justify-content-around">
                <div class="col-lg-2 col-4">
                    <h1 class="text-center mb-0" style="font-size: calc(1rem + 1.2vw)">150,000+</h1>
                    <p class="text-center mt-0">طالب</p>
                </div>
                <div class="col-lg-2 col-4">
                    <h1 class="text-center mb-0" style="font-size: calc(1rem + 1.2vw)">7000+</h1>
                    <p class="text-center mt-0">معلم</p>
                </div>
                <div class="col-lg-2 col-4">
                    <h1 class="text-center mb-0" style="font-size: calc(1rem + 1.2vw)">300,000+</h1>
                    <p class="text-center mt-0">ساعات تعلم</p>
                </div>

            </div>
            <h1 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>تواجدنا</strong></h1>
            <div class="row pt-5 m-0 justify-content-center">
                <div class="col-12 col-sm-12 col-md-10 col-lg-8">
                    <img src="@/assets/sa/img/OUR_PRESENCE.jpg" class="img-fluid">
                </div>
            </div>
        </div>
        <div class="container-fluid pt-5" style="background:#EFEFF7">
            <h1 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>طريقة التسجيل</strong></h1>
            <div class="row pt-5 m-0 justify-content-end">
                <div class="col-6 col-md-6 col-lg-3">
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633;">الخطوة</span> 1</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        احجز تجربة مجانية لحضور محاضرة معنا. تستطيع حجز تجربة مجانية مباشرة من موقعنا الرسمي

                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">الخطوة</span> 2</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        يجب عليك التسجيل وإدخال بياناتك الصحيحة في أماكنها المخصصة

                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">الخطوة</span> 3</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        تستطيع حجز تجربة مجانية في الوقت الذي يتناسب معك. تتوفر العديد من الأوقات والتواريخ للحجز.
                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">الخطوة</span> 4</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        تستغرق جلسة التجربة المجانية مع الأساتذة من 40 دقيقة إلى ساعة.
                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">الخطوة</span> 5</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        سيتواصل معك ممثل الفريق فيما يخص رأيك عن التجربة المجانية ولمشاركة المزيد من المعلومات عن الدورة
                    </h5>
                </div>
                <div class="col-6 col-md-6 col-lg-3">


                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">الخطوة</span> 6</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        إذا أعجبتك المحاضرة التجريبية، يمكنك الاستمرار عن طريق شراء أحد باقات الدورة.

                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">الخطوة</span> 7</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        تستطيع جدولة محاضراتك الأسبوعية (يفضل 2-3 محاضرات في الأسبوع) ليتمكن طفلك من الاستمرار في مسيرته التعليمية معنا.
                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">الخطوة</span> 8</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        بعد شراء أحد باقات الدورة، تستطيع الدخول إلى قسم التعلم الذاتي عن طريق تطبيقنا الرسمي Let’s Tinker. لتتمتع بالمرونة في التعلم أينما كنت وفي أي وقت
                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">الخطوة</span> 9</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        عند الانتهاء من الدورة التحضيرية، يمكنك التسجيل في أحد دوراتنا المتخصصة على حسب اهتماماتك كتصميم الألعاب، الذكاء الاصطناعي، انترنت الأشياء أو علم الروبوتات.
                    </h5>
                </div>
                <div class="col-sm-7 col-md-6 col-lg-4 position-relative col-10 align-self-end p-0">
                    <img class="img-fluid" style="transform:rotateY(180deg);" src="@/assets/india/image/how-it.png" />
                </div>
            </div>

        </div>
        <div class="container-fluid py-5 position-relative" style="background-position: left bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: rgb(105, 212, 196);"
             :style="{'backgroundImage': 'url('+require('@/assets/india/image/faq-rtl.png')+')'}">

            <h1 class="text-center p-5" style="padding-bottom:0px !important;"> <span style="border-color:#FF9999"><strong>الأسئلة الشائعة</strong></span></h1>
            <div class="row py-5 m-0 justify-content-end" style="min-height:500px;">
                <div class="col-12 col-md-8 col-lg-7">
                    <vue-collapsible-panel-group accordion>
                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                على أي منصة سيتم عرض الدورة؟

                            </template>
                            <template #content>
                                هناك نوعان من الجلسات:
                                جلسات مسجلة سيتم تسليمها عن طريق تطبيق Let’s Tinker مضاف معها الامتحانات والواجبات. ومحاضرات مباشرة عن طريق جوجل ميت / زوم

                            </template>
                        </vue-collapsible-panel>

                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                هل هناك سياسة لاسترجاع المدفوعات؟ وما شروطها؟
                            </template>
                            <template #content>
                                تستطيع الغاء الاشتراك في أي وقت خلال فترة الأسبوعين الأولى أو تغيير نوع الباقة على حسب الطلب.
                                سياسة الاسترجاع لدينا هي أننا لا نسأل أبداً. ليس هناك أي رسوم الغاء اشتراك واذا طلبت الإلغاء بعد مرور فترة الأسبوعين سيتم فقط اقتطاع مبلغ المحاضرات الفائتة ومحفظة الأدوات.

                            </template>
                        </vue-collapsible-panel>

                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                ماهي اللغات التي ستعقد بها الدورة؟
                            </template>
                            <template #content>
                                حالياً بالإنجليزية فقط.

                            </template>
                        </vue-collapsible-panel>
                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                هل هناك رسوم على المحاضرات التجريبية؟
                            </template>
                            <template #content>
                                سيتم جدولة المحاضرات التجريبية مجانا للأطفال وستضم شهادة مشاركة أيضاً تستطيع تحميلها عن طريق التطبيق.
                            </template>
                        </vue-collapsible-panel>
                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                هل جودة وخبرة المدرسين مضمونة؟
                            </template>
                            <template #content>
                                تم تجميع دوراتنا من قبل فريق من المهندسين الذين تخرجوا أفضل الجامعات عالمياً. يمتلك الفريق خبرة مكثفة في تدريب المدرسين والطلاب في أكثر من 500 مدرسة. سيتم القاء المحاضرات المباشرة من قبل خريجين أفضل المعاهد مع خبرة في التدريس والتوجيه. يتم تدريب جميع مدرسينا بصرامة للتأكيد على إيصال أفضل المعطيات للطلاب.
                            </template>
                        </vue-collapsible-panel>
                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                متى يستطيع الطفل التسجيل في دوراتنا المتخصصة؟
                            </template>
                            <template #content>
                                نوفر دورات متخصصة للطلاب الذين اجتازوا الدورة الأساسية. اختيار الدورات المتخصصة مبني على اهتمامات طفلك, وحددنا شرط اجتياز الدورة الأساسية حتى يتسنى لطفلك استيعاب التكنولوجيا ومعرفة ما يثير اهتمامه أكثر.
                            </template>
                        </vue-collapsible-panel>
                    </vue-collapsible-panel-group>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-9 col-md-7">
                    <h4 class="text-center font-weight-bolder">
                        هل أنت مهتم في<br />اكتشاف حب طفلك للبرمجة؟
                    </h4>
                    <p class="text-center ">
                        <router-link :to="{ name: 'BookDemoAR',params: { username: $route.params.username }}" v-slot="{ href, navigate}" custom>
                            <a class="btn btn-warning m-2 btn_orange_active" :href="href" @click="navigate" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">احجز تجربة مجانية</a>
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
        <footer class="pt-4 pl-4 pb-4" style="background:#EFEFEF">

            <div class="row m-0">
                <div class="col-md-4 p-5">
                    <!-- //logo image -->
                    <a href="https://code.tinker.ly/">
                        <img src="@/assets/india/image/logo.png" style="max-width:250px;" class="img-fluid" /></a>

                        <p style="font-size:1.2em" class="mt-4">
                            تأسست تينكرلي في عام 2015, هي رائدة في مجال تقديم الدورات المدمجة مع الباقات التعليمية في مجال الذكاء الاصطناعي، انترنت الأشياء، علم الروبوتات وتطوير الألعاب. مناهجنا المبنية على اللعب والمرح تجعل التعليم ممتعاً ومشوق، بالإضافة إلى تعزيز المهارات التي ستكون لازمة في وظائف المستقبل.
                        </p>


                </div>
                <div class="col-md-8">
                    <div class="footer-menu">
                        <span class="menu-top-border"></span>
                        <div class="menu-footer-menu-container">
                            <ul class="menu-list">
                                <li class="menu-item "><a title="LetsTinker'" target="_blank" href="https://alpha.letstinker.tinker.ly/" class="nav-link" itemprop="url"><span itemprop="name">آئیے ٹنکر</span></a></li>
                                <li class="menu-item "><a title="About Us" target="_blank" href="https://tinker.ly/about-us/" class="nav-link" itemprop="url"><span itemprop="name">نبذة عنا</span></a></li>
                                <li class="menu-item "><a title="Contact us" target="_blank" href="https://tinker.ly/contact-us/" class="nav-link" itemprop="url"><span itemprop="name">تواصل معنا</span></a></li>
                                <li class="menu-item "><a title="Lets Tinker" target="_blank" href="https://letstinker.tinker.ly/" class="nav-link" itemprop="url"><span itemprop="name">لنلعب ونصنع</span></a></li>
                                <li class="menu-item "><a title="Partner with us" target="_blank" href="https://tinker.ly/partner/" class="nav-link" itemprop="url"><span itemprop="name">تعاقد معنا</span></a></li>
                                <li class="menu-item "><a title="Blog" target="_blank" href="https://tinker.ly/category/international/" class="nav-link" itemprop="url"><span itemprop="name">المقالات</span></a></li>
                                <li class="menu-item "><a title="Become a Teacher" target="_blank" href="https://tinker.ly/join-tinkerlys-teacher-program-to-enlighten-young-minds-about-stem-coding/" class="nav-link" itemprop="url"><span itemprop="name">أصبح معلماَ</span></a></li>

                            </ul>
                        </div>
                        <span class="menu-bottom-border"></span>

                    </div>
                    <div class="row justify-content-end">
                        <div class="col-md-10">
                            <h4 class="text-mohave font-weight-bold">صفحات التواصل الاجتماعي</h4>
                            <p style="color:black;font-size:1.2em">تابعونا لتبقوا على تواصل معنا وليصلكم كل جديد</p>
                            <ul class="menu-list p-0 m-0 align-self-end">

                                <li class="menu-item " style="font-size:2em;font-weight:900;margin-right: 25px">
                                    <a href="https://instagram.com/tinkerly_me?utm_medium=copy_link" target="_blank"><img style="height:35px" src="@/assets/svg/instagram-brands.svg" /></a>
                                </li>



                                <li class="menu-item " style="font-size:2em;font-weight:900;margin-right: 25px">
                                    <a href="https://www.facebook.com/tinkerlygroup/" target="_blank"><img style="height:35px" src="@/assets/svg/facebook-square-brands.svg" /></a>
                                </li>



                                <li class="menu-item " style="font-size:2em;font-weight:900;margin-right: 25px">
                                    <a href="https://www.youtube.com/channel/UCJccc_N6nxOtOd_2r2OCRRg" target="_blank"><img style="height:35px" src="@/assets/svg/youtube-brands.svg" /></a>
                                </li>



                                <li class="menu-item " style="font-size:2em;font-weight:900;margin-right: 25px">
                                    <a href="https://www.linkedin.com/company/tinkerlygroup/" target="_blank"><img style="height:35px" src="@/assets/svg/linkedin-brands.svg" /></a>
                                </li>





                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container mt-5">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-md-12 base-footer-left">
                        <span class="base-footer-left-top-border"></span>
                        <p style="font-size:1.2em">حقوق النشر © Elation Edtech Pvt Ltd 2021. جميع الحقوق محفوظة</p>
                    </div>
                    <div class="col-lg-6 col-md-12 col-md-12 base-footer-right-links">
                        <ul class="menu-list">
                            <li class="menu-item"><a style=" border-left: 1px solid #000;margin-left:25px;padding-left:25px" href="https://tinker.ly/privacy-policy-for-tinkerly/" target="_blank">سياسة الخصوصية</a></li>
                            <li class="menu-item"><a style=" margin-left:25px" href="https://tinker.ly/terms-and-conditions/" target="_blank">الشروط والأحكام</a></li>
                            <li class="menu-item"><a style="border-right: 1px solid #000;padding-right:25px" href="https://tinker.ly/guidelines-for-setting-up-of-atal-tinkering-laboratories/" target="_blank">Guidelines for ATL</a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</template>
<style  scoped>

*{
  font-family: pnu;  
}
.try_half{
    width: 50% !important;
}
.menu-list{list-style-type:none}
.menu-item{list-style-type:none;display:inline-block;color:#000}
.menu-item a{font-size:1.2em;color:#000}
.menu-bottom-border{border-bottom:1px solid #000;display:inline-block;width:95%;position:relative;right:0;bottom: 16px;}
.menu-top-border{border-bottom:1px solid #000;display:inline-block;width:97%;position:relative;left:-94px;bottom:16px}
.base-footer-left-top-border{border-bottom:1px solid #000;display:block;width:20%;position:relative;left:0;bottom:12px}
.grade_display{
        top: 12%;
    left: 10%;
    z-index: 1;
    color: #fff;
    font-weight: 600;
    font-size: 120%;
    position: absolute;
}
.count_display{
        top: 10%;
    
    color: black;
    font-size: 160%;
    z-index: 1;
    font-weight: 700;
    position: absolute;
}
.Curriculum_display{
    position: absolute;
    
    left: 22%;
    width: 74%;
    font-size: 95%;
}
.Curriculum_display span{
    font-size: 120%;
    font-weight: 600;
    color: #FF6634;
}

.price_display{
    position: absolute;
    top: 88.5%;
    left: 32%;
    font-weight: 600;
}
.price_cut_display{
    position: absolute;
        top: 88.5%;
    left: 65%;
    font-weight: 600;
    color: #999999;
    text-decoration: line-through;
}
.row, .container-fluid ,.container {
    overflow-x: hidden;
}
    .position-relative .position-absolute{
        width:auto;
    }
    .position-absolute {
        z-index: -1;
    }
    .ArabicLan .text-right ,.ArabicLan .text-md-right{
        text-align:left;
    }
    
    
    h1{
        font-weight:600;
    }
        h1 span {
            border-bottom: 4px solid;
            display: inline-block;
            line-height: 35px;
           
        }
    .btn_blue_active {
        background: #2BB7D0;
        color: #fff;
        border: 2px #2BB7D0 solid;
    }
        .btn_blue_active:hover {
            background: #26A2B8
        }
    .btn_orange_active {
        background: #FF6633;
        color:#fff;
        border:2px #FF6633 solid;
    }
        .btn_orange_active:hover {
            background: #E65C2E;
            color: #fff;
            border: 2px #FF6633 solid;
        }
        .btn_orange {
            background: #fff;
            color: #FF6633;
            border: 2px #FF6633 solid;
        }
    .btn_orange:hover {
        background: #FF6633;
        color: #fff;
    }
    .No_cost_emi {
        background: #fff;
        line-height: 18px;
        border-radius: 12px !important;
    }
        .No_cost_emi span {
            font-size: 1rem;
            line-height: 10px;
            color: #B3B3B3;
        }
    .mobile {
        border: 1px solid white;
        box-shadow: 0px 0px 3px 3px #e9e8e8;
        border-radius: 10px;
        background: white;
        padding-top: 0px !important;
    }
        .mobile p span {
            width: 10px !important;
            display: inline-block;
        }
    .s_red:before {
        content: '\25CF';
        color: #FF6634;
        display: inline-block;
        width: 6px;
    }
    .s_green:before {
        content: '\25CF';
        color: #65C3A4;
        display: inline-block;
        width: 6px;
    }
    .s_yello:before {
        content: '\25CF';
        color: #F9A137;
        display: inline-block;
        width: 6px;
    }
</style>
<script>

    import '@/assets/css/rtl.bootstrap.min.css'
     import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper-bundle.min.css';
    import '@/assets/Font/rtl.css'
    import { VueCollapsiblePanel, VueCollapsiblePanelGroup} from '@dafcoe/vue-collapsible-panel'
    import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css'
    import {trackerFBPixel} from '@/assets/js/TinkerlyTracking.js';
export default {
  name: 'Home',
        components: {
            Swiper,
            SwiperSlide,
            VueCollapsiblePanelGroup,
            VueCollapsiblePanel
        },
        data() {
            return {
                Mysyper:"",
                grade:1,
                achever:546.4,
                lerner:106.4,
                StudentInit:"",
                YoutubeInit:"",
                TeacherInit:"",
                ParentInit:"",
                CourseInit:"",
                pdfName:{
                    m1g1:"Tinkerly - Curriculum Age 6 ar.pdf",
                    m1g2:"Tinkerly - Curriculum Age 8 ar.pdf",
                    m1g3:"Tinkerly - Curriculum Age 12 ar.pdf",
                    m1g4:"Tinkerly - Curriculum Age 14 ar.pdf"
                },
             };
        },
        methods: {
            LanguageChange(){
               this.$router.push({name:'SaHome', params: {username: this.$route.params.username}}); 
            },
            onSwiper(swiper) {
                this.Mysyper = swiper;
            },
            onStudentInit(swiper){
                this.StudentInit = swiper;
            },
           onYoutubeInit(swiper){
                this.YoutubeInit = swiper;
           },
           onCourseInit(swiper){
               this.CourseInit = swiper;
           },
           onTeacherInit(s){
               this.TeacherInit = s;
           },
           OnParentInit(s){
               this.ParentInit = s;
           },
            downloadPdf(){
                   trackerFBPixel('Download');
     this.$gtag.event("conversion", {
              send_to: "AW-810557807/I6CpCJXGgYYDEO_CwIID",
            });
                var key = "m1g"+this.grade;
                window.open("/pdf/"+this.pdfName[key], '_blank').focus();
               
            },
            setGrade(g){
                this.grade = g;
            },
            
            scrollToCourse(){
                const chatLogDiv = document.getElementById('MyCourseDiv')
                var top = chatLogDiv.offsetTop;
                window.scrollTo(0, top);
                trackerFBPixel('Price');
                 this.$gtag.event("conversion", {
              send_to: "AW-810557807/qRCnCM2mz4UDEO_CwIID",
            });
            }

    },
   computed: {
    getAcheverPrice:function(){
       let famount = (this.achever*this.$store.state.multipllayer)
                famount = Math.ceil(famount);
                return this.$store.state.displayc+" "+famount;
    } ,
    getLernerPrice:function(){
       let famount = (this.lerner*this.$store.state.multipllayer)
                famount = Math.ceil(famount);
                return this.$store.state.displayc+" "+famount;
    } 
  },
  mounted: function(){
  window.__lc = window.__lc || {};
  window.__lc.license = 13188978;
  (function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}
  var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){
  i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},
  get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");
  return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){
  var n=t.createElement("script");
  n.async=!0,n.type="text/javascript",
  n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};
  !n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
    }

}
</script>


